html {
    scroll-behavior: smooth;
}

.adminAddressListDiv {
    padding: 15px;
    width: 100%;
}
.adminAddressListDiv p {
    font-size: 20px;
    font-weight: 500 !important;
    letter-spacing: -0.3px;
    text-align: center;
    padding: 10px 0px;
}
.adminAddressListDiv ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.adminAddressListDiv ul li {
    padding: 10px 15px;
    margin: 15px 0px;
    background-color: #f2f2f2;
    border-radius: 10px;
    display: block;
    width: 100%;
}

.adminAddressListDiv ul li i {
    font-style: normal;
    white-space: pre-wrap;
    width: auto;
    height: auto;
    display: block;
}
.adminAddressListDiv ul li span {
    margin-left: auto;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.05);
    margin-top: 1px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}
.adminAddressListDiv ul li span:hover {
    background-color: rgba(0, 0, 0, 0.15);
}
.adminAddressListDiv ul li span svg {
    width: 15px;
    height: 15px;
}
.adminAddressListDiv ul li strong {
    margin-right: 10px;
}

.soroundAddressDiv {
    padding: 50px 300px;
}
.soroundAddressDiv .addressFormComponent {
    padding: 0px 100px;
}

.soroundAddressDiv .allAddressComponent {
    padding: 50px 0px;
}

/* BCMT Pay */
.bcmtPayContainer {
    padding: 10px 400px;
}
.bcmtPayContainer .midComps {
    padding: 0px 80px;
}
.nextButton {
    padding: 13px 30px;
    margin-top: 20px;
    background-color: #3f51b5;
    color: #fff;
    box-shadow: none;
    border: none;
    cursor: pointer;
    outline: none;
}

@media (max-width: 1180.5px) {
    .soroundAddressDiv {
        padding: 50px 200px;
    }
    .bcmtPayContainer {
        padding: 10px 340px;
    }
    .bcmtPayContainer .midComps {
        padding: 0px 80px;
    }
}

@media (max-width: 1080.5px) {
    .bcmtPayContainer .midComps {
        padding: 0px 40px;
    }
}

@media (max-width: 991.5px) {
    .soroundAddressDiv {
        padding: 50px 150px;
    }
    .soroundAddressDiv .addressFormComponent {
        padding: 0px 60px;
    }
    .bcmtPayContainer {
        padding: 10px 240px;
    }
    .bcmtPayContainer .midComps {
        padding: 0px 70px;
    }
}

@media (max-width: 991.5px) {
    .soroundAddressDiv {
        padding: 50px 100px;
    }
}

@media (max-width: 865.5px) {
    .bcmtPayContainer .midComps {
        padding: 0px 30px;
    }
}

@media (max-width: 767.5px) {
    .soroundAddressDiv {
        padding: 50px 70px;
    }
    .soroundAddressDiv .addressFormComponent {
        padding: 0px 80px;
    }
    .bcmtPayContainer {
        padding: 10px 100px;
    }
    .bcmtPayContainer .midComps {
        padding: 0px 100px;
    }
}

@media (max-width: 715.5px) {
    .soroundAddressDiv {
        padding: 50px 30px;
    }
    .soroundAddressDiv .addressFormComponent {
        padding: 0px 60px;
    }
}

@media (max-width: 630.5px) {
    .soroundAddressDiv {
        padding: 50px 0px;
    }
    .soroundAddressDiv .addressFormComponent {
        padding: 0px 60px;
    }
    .bcmtPayContainer {
        padding: 10px 100px;
    }
    .bcmtPayContainer .midComps {
        padding: 0px 50px;
    }
}

@media (max-width: 600.5px) {
    .soroundAddressDiv .addressFormComponent {
        padding: 0px 30px;
    }
    .adminAddressListDiv {
        padding: 15px 0px;
    }
}

@media (max-width: 500.5px) {
    .soroundAddressDiv .addressFormComponent {
        padding: 0px 10px;
    }
    .bcmtPayContainer {
        padding: 10px 0px;
    }
    .bcmtPayContainer input {
        width: 250px !important;
    }
    .bcmtPayContainer .midComps {
        padding: 0px;
    }
}

@media (max-width: 390.5px) {
    .soroundAddressDiv .addressFormComponent {
        padding: 0px 10px;
    }
    .adminAddressListDiv ul li {
        padding: 10px 15px;
        margin: 15px 0px;
        background-color: #f2f2f2;
        border-radius: 10px;
        display: block;
        width: 100%;
    }
    .adminAddressListDiv ul li i {
        font-size: 13px;
    }
}

@media (max-width: 375.5px) {
    .adminAddressListDiv ul li i {
        font-size: 12px;
    }
}

@media (max-width: 355.5px) {
    .adminAddressListDiv ul li i {
        font-size: 11px;
    }
    .bcmtPayContainer input {
        width: 200px !important;
    }
}

